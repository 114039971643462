import "./HomeDisplay.css"

function HomeDisplay() {
    return (
        <div className="HomeDisplay">
            <div className="HomeDisplay-tag logical">Hi, I'm Michael, a full stack web development and software engineer</div>
        </div>
    )
}

export default HomeDisplay